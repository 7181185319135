<template>
  <div class="app_structure_wrapper">
    <TheHeader />
    <!-- Start:: Content Body -->
    <div class="dashboard_body_wrapper">
      <div class="inner_structure_wrapper">
        <!-- ********** Start:: Header ********** -->
        <!-- ********** End:: Header ********** -->

        <!-- ********** Start:: App Router View ********** -->
        <section class="main_content">
          <transition name="fadeInUp" mode="out-in">
            <router-view />
          </transition>
        </section>
        <!-- ********** End:: App Router View ********** -->
      </div>
    </div>
    <!-- End:: Content Body -->
    <TheFooter />
  </div>
</template>

<script>
// Start:: Importing Vuex Helpers
import {mapGetters } from "vuex";
// End:: Importing Vuex Helpers

// STart:: Importing Navigations
import TheHeader from "@/components/navigation/TheHeader.vue";
import TheFooter from "@/components/navigation/TheFooter.vue";

// import TheSideNav from "@/components/navigation/TheSideNav.vue";
// End:: Importing Navigations


export default {
  name: "AppContentWrapper",

  components: {
    TheHeader,
    TheFooter
    // TheSideNav,
  },

  computed: {
    // Start:: Vuex Get Authenticated User Data
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData",
    }),
    // End:: Vuex Get Authenticated User Data
  },

  data() {
    return {
    }
  },

  methods: {

  },

  mounted() {
    // Start:: Fire Methods
    // if (this.getAuthenticatedUserData.token) {
    //   this.receiveMessage();
    // }
    // End:: Fire Methods
  },
};
</script>
