<template>
  <div class="auth_wrapper">
    <!-- Start:: Auth Router View -->
    <TheHeader />
    <div class="container-fluid" style="min-height:60vh">
      <div class="row">      
            <transition name="fadeInUp" mode="out-in">
              <router-view />
            </transition>
      </div>
    </div>
    <TheSubFooter />
    <!-- End:: Auth Router View -->
  </div>
</template>
<script>
import TheHeader from '@/components/navigation/TheHeader.vue';
import TheSubFooter from '@/components/navigation/TheSubFooter.vue';
export default {
  components: { TheHeader, TheSubFooter },
  name: "Authentication",

  computed: {

  },

  data() {
    return {
    }
  },

  methods: {

  },
};
</script>
