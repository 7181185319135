export default {
    // START:: SET AUTHENTICATED USER DATA
    setAuthenticatedUserData(state, payload) {
        if (payload.id) {
            state.userId = payload.id;
            localStorage.setItem("urc_user_id", payload.id);
        }
        if (payload.token) {
            state.userToken = payload.token;
            localStorage.setItem(
                "urc_user_token",
                payload.token
            );
        }
        if (payload.type) {
            state.userType = payload.type;
            localStorage.setItem(
                "urc_user_type",
                payload.type
            );
        }
        if (payload.name) {
            state.userName = payload.name;
            localStorage.setItem(
                "urc_userName",
                payload.name
            );
        }
        if (payload.email) {
          state.email = payload.email;
          localStorage.setItem(
            "urc_email",
            payload.email
          );
        }
        if (payload.avatar) {
            state.userAvatar = payload.avatar;
            localStorage.setItem(
                "urc_user_avatar",
                payload.avatar
            );
        }
    },
    // END:: SET AUTHENTICATED USER DATA
};
