<template>
  <div class="home_page_wrapper">
    <div class="container">
      <div class="row">
        <h2 style="color: #1552E9;">
          Welcome,<br>
           {{user.name}}<br>
          Start your journey into Urgent Care 24
        </h2>
      </div>
      <div class="row">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item page" role="presentation">
              <button class="nav-link active" id="live-tab" data-bs-toggle="pill" data-bs-target="#live" type="button" role="tab" aria-controls="live" aria-selected="true">Live Streams</button>
            </li>
            <li class="nav-item page" role="presentation">
              <button class="nav-link" id="certification-tab" data-bs-toggle="pill" data-bs-target="#certification" type="button" role="tab" aria-controls="certification" aria-selected="false">Your Certificate</button>
            </li>
          </ul>
          <p class="text-center mt-10 mb-10" style="color:#1552E9">
            Your attendance 
          </p>
          <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="live" role="tabpanel" aria-labelledby="live-tab" tabindex="0">
                <div class="row">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li class="nav-item mr-5 text-center active" role="presentation">
                        <button class="nav-link  day" :class="{'green':isAttended}" id="live1-tab" data-bs-toggle="pill" data-bs-target="#live1" type="button" role="tab" aria-controls="live1" aria-selected="true"><b style="font-size:40px"> 9 </b>  February</button>
                         <span class="attend_status" v-if="!isAttended">
                            Not yet
                         </span>
                         <span style="color:#4CE052" v-if="isAttended">
                            <img src="@/assets/images/is_attend.png"> Attended
                         </span>
                      </li>

                      <li class="nav-item text-center" role="presentation">
                        <button class="nav-link day" :class="{'green':isAttended}" id="live2-tab" data-bs-toggle="pill" data-bs-target="#live2" type="button" role="tab" aria-controls="live2" aria-selected="false"> <b style="font-size:40px"> 10</b> February</button>
                        <span class="attend_status" v-if="!isAttended">
                            Not yet
                         </span>
                         <span style="color:#4CE052" v-if="isAttended">
                            <img src="@/assets/images/is_attend.png"> Attended
                         </span>
                      </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active live mb-10" id="live1" role="tabpanel" aria-labelledby="live1-tab" tabindex="0">Live 1</div>
                        <div class="tab-pane fade live" id="live2" role="tabpanel" aria-labelledby="live2-tab" tabindex="0">live 2</div>
                    </div>
                </div>

              </div>
              <div class="tab-pane fade" id="certification" role="tabpanel" aria-labelledby="certification-tab" tabindex="0">
                  <div class="row" style="justify-content:center">
                      <img src="@/assets/images/thank.png" style="width:115px;height:112px" class="mt-5">
                      <p class="text-center mt-5" style="color:#1552E9;font-size:18px">
                        Thank you for being a part of Urgent care 24
                      </p>
                      <p class="text-center mt-5" style="color:#1552E9;font-size:18px">
                        <b>Your Certificate will be ready after event ended</b>
                      </p>
                  </div>
              </div>
          </div>
      </div>

    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions} from "vuex";

export default {
  name: "HomePage",

  components: {
  },
  data(){
    return{
      isAttended:false,
      user: null
    }
  },
  computed: {
    // Start:: Vuex Getters
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData",
    }),
    // End:: Vuex Getters
  },
  methods: {
  
  },

  mounted() {
    // START:: FIRE METHODS
    // END:: FIRE METHODS
  },
  created() {
    this.user = this.getAuthenticatedUserData;
  },
};
</script>

<style scoped>
.nav-pills{
  display: flex;
  justify-content: center;
}
.page{
  border-radius: 17px;
  border: 2px solid #1552E9;
  color: #1552E9;
  margin: 10px;
  font-size: 20px;
  font-weight: 500;
}
.page .nav-link:hover{
  background-color: #1552E9;
  color: #FFF;
}

.live{
  width: 100%;
  height: 400px;
  background: #1552E9;
  color: #FFF;
  text-align: center;
}
.day{
  width: 160px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #9FA1A8;
  color: #FFF;
  border: 0;
}
.nav-link:hover{
  color: #FFF;
}
.green{
  background-color: #4CE052 !important;
}

.day .nav-link.active, .nav-pills .show > .nav-link{
  background: none;
}
.attend_status{
  color: #9FA1A8;
  font-weight: 400;
  font-size: 15px;
}
</style>