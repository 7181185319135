<template>
  <div class="side_nav_wrapper" :class="{ active: navIsActive }">
    <!-- Start:: Toggle Btn -->
    <button class="close_btn" @click="$emit('fireToggleNavDrawerEmit')">
      <i class="fal fa-times"></i>
    </button>
    <!-- End:: Toggle Btn -->

    <!-- Start:: Logo -->
    <div class="logo_wrapper">
      <router-link class="logo" to="/home">
        <img
          src="@/assets/media/logo/logo.svg"
          alt="Logo"
          width="220"
          height="100"
        />
      </router-link>
    </div>
    <!-- End:: Logo -->

    <!-- Start:: Side Nav Links -->
    <div class="side_nav_links_wrapper">
      <!-- Start:: Home Route -->
      <div class="home_route" @click="$emit('fireToggleNavDrawerEmit')">
        <router-link to="/home">
          <span class="route_icon">
            <i class="fal fa-home-lg-alt"></i>
          </span>
          <span class="route_text">
            {{ $t("SIDENAV.dashboard") }}
          </span>
        </router-link>
      </div>
      <!-- End:: Home Route -->

      <!-- Start:: Side Nav Routes -->
      <div class="side_routes_wrapper">
        <a-menu
          style="width: 100%"
          mode="inline"
          :open-keys="openKeys"
          @openChange="onOpenChange"
        >
          <!-- Start:: Side Nav Tab -->
          <a-sub-menu
            v-for="item in sideNavbarList"
            :key="item.key"
            :data-type="!item.children ? 'single_route' : ''"
          >
            <template v-if="item.children">
              <!-- ========= Start:: Main Tab -->
              <span slot="title" class="submenu_tab_name">
                <i :class="item.icon"></i>
                <span> {{ item.title }} </span>
              </span>
              <!-- ========= End:: Main Tab -->

              <!-- ========= Start:: Nested Tabs -->
              <a-menu-item
                v-for="childItem in sideNavbarItemChildren(item.children)"
                :key="childItem.hey"
              >
                <button
                  class="text-start w-100"
                  @click="$emit('fireToggleNavDrawerEmit')"
                >
                  <router-link :to="childItem.route">
                    <i class="fad fa-circle"></i>
                    {{ childItem.title }}
                  </router-link>
                </button>
              </a-menu-item>
              <!-- ========= End:: Nested Tabs -->
            </template>

            <template v-else-if="!item.children">
              <!-- ========= Start:: Single Tab -->
              <button
                class="text-start d-flex w-100"
                slot="title"
                @click="$emit('fireToggleNavDrawerEmit')"
              >
                <router-link :to="item.route" class="tab_name">
                  <i :class="item.icon"></i>
                  <span> {{ item.title }} </span>
                </router-link>
              </button>
              <!-- ========= End:: Single Tab -->
            </template>
          </a-sub-menu>
          <!-- End:: Side Nav Tab -->
        </a-menu>
      </div>
      <!-- End:: Side Nav Routes -->

      <!-- Start:: Logout Tab -->
      <button
        class="logout_btn"
        @click="logoutConfirmationModalIsOpen = true"
      >
        <i class="fal fa-sign-out"></i>
        <span>
          {{ $t("SIDENAV.Logout.title") }}
        </span>
      </button>
      <!-- End:: Logout Tab -->

      <!-- Start:: Logout Confirmation Modal -->
      <v-dialog v-model="logoutConfirmationModalIsOpen">
        <v-card>
          <v-card-title class="justify-center">
            {{ $t("TITLES.logoutConfirmingMessage") }}
          </v-card-title>
          <v-card-actions>
            <v-btn class="modal_confirm_btn" @click="logout">{{
              $t("BUTTONS.logout")
            }}</v-btn>

            <v-btn
              class="modal_cancel_btn"
              @click="logoutConfirmationModalIsOpen = false"
              >{{ $t("BUTTONS.cancel") }}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- End:: Logout Confirmation Modal -->
    </div>
    <!-- End:: Side Nav Links -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SideNav",

  emits: ["fireToggleNavDrawerEmit"],

  props: {
    navIsActive: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    // Start:: Vuex Get Authenticated User Data
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData",
      authedUserPermissions: "PermissionsModule/authedUserPermissions",
    }),
    // End:: Vuex Get Authenticated User Data

    // Start:: Side Navbar Items
    sideNavbarList() {
      let sideNavRoutes = [
        {
          key: "books",
          title: this.$t("SIDENAV.Books.title"),
          icon: "fal fa-book",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.Books.showAll"),
              route: "/books/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.Books.add"),
              route: "/books/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "protocolsCategories",
          title: this.$t("SIDENAV.ProtocolsCategories.title"),
          icon: "fal fa-folders",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.ProtocolsCategories.showAll"),
              route: "/protocols-categories/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.ProtocolsCategories.add"),
              route: "/protocols-categories/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "countries",
          title: this.$t("SIDENAV.Countries.title"),
          icon: "fal fa-globe-asia",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.Countries.showAll"),
              route: "/countries/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.Countries.add"),
              route: "/countries/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "cities",
          title: this.$t("SIDENAV.Cities.title"),
          icon: "fal fa-flag",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.Cities.showAll"),
              route: "/cities/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.Cities.add"),
              route: "/cities/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "areas",
          title: this.$t("SIDENAV.Areas.title"),
          icon: "fal fa-city",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.Areas.showAll"),
              route: "/areas/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.Areas.add"),
              route: "/areas/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "educations",
          title: this.$t("SIDENAV.Educations.title"),
          icon: "fal fa-graduation-cap",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.Educations.showAll"),
              route: "/educations/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.Educations.add"),
              route: "/educations/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "specialties",
          title: this.$t("SIDENAV.Specialties.title"),
          icon: "fal fa-sparkles",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.Specialties.showAll"),
              route: "/specialties/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.Specialties.add"),
              route: "/specialties/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "powerPoints",
          title: this.$t("SIDENAV.PowerPoints.title"),
          icon: "fal fa-file-powerpoint",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.PowerPoints.showAll"),
              route: "/power-points/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.PowerPoints.add"),
              route: "/power-points/create",
              hasPermission: true,
            },
          ],
        },
        // {
        //   key: "mcqs",
        //   title: this.$t("SIDENAV.Mcqs.title"),
        //   icon: "fal fa-question-circle",
        //   hasPermission: true,
        //   children: [
        //     {
        //       key: "index",
        //       title: this.$t("SIDENAV.Mcqs.showAll"),
        //       route: "/mcqs/all",
        //       hasPermission: true,
        //     },
        //     {
        //       key: "create",
        //       title: this.$t("SIDENAV.Mcqs.add"),
        //       route: "/mcqs/create",
        //       hasPermission: true,
        //     },
        //   ],
        // },
        {
          key: "lectures",
          title: this.$t("SIDENAV.Lectures.title"),
          icon: "fal fa-presentation",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.Lectures.showAll"),
              route: "/lectures/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.Lectures.add"),
              route: "/lectures/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "articles",
          title: this.$t("SIDENAV.ArticlesCategories.title"),
          icon: "fal fa-newspaper",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.ArticlesCategories.showAll"),
              route: "/articles-categories/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.ArticlesCategories.add"),
              route: "/articles-categories/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "admins",
          title: this.$t("SIDENAV.admins.title"),
          icon: "fal fa-user",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.admins.showAll"),
              route: "/admins/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.admins.add"),
              route: "/admins/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "authors",
          title: this.$t("SIDENAV.authors.title"),
          icon: "fal fa-users",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.authors.showAll"),
              route: "/authors/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.authors.add"),
              route: "/authors/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "contact-us",
          title: this.$t("SIDENAV.contact-us.title"),
          icon: "fal fa-inbox",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.contact-us.showAll"),
              route: "/contact-us/all",
              hasPermission: true,
            }
          ],
        },
        {
          key: "latest-news",
          title: this.$t("SIDENAV.latest-news.title"),
          icon: "fal fa-rss-square",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.latest-news.showAll"),
              route: "/latest-news/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.latest-news.add"),
              route: "/latest-news/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "photos",
          title: this.$t("SIDENAV.photos.title"),
          icon: "fal fa-images",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.photos.showAll"),
              route: "/photos/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.photos.add"),
              route: "/photos/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "reviews",
          title: this.$t("SIDENAV.reviews.title"),
          icon: "fal fa-quote-right",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.reviews.showAll"),
              route: "/reviews/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.reviews.add"),
              route: "/reviews/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "policiesCategories",
          title: this.$t("SIDENAV.PoliciesCategories.title"),
          icon: "fal fa-folders",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.PoliciesCategories.showAll"),
              route: "/policies-categories/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.PoliciesCategories.add"),
              route: "/policies-categories/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "settings",
          title: this.$t("SIDENAV.settings.title"),
          icon: "fal fa-cogs",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.settings.showAll"),
              route: "/settings/all",
              hasPermission: true,
            }
          ],
        },
        {
          key: "teams",
          title: this.$t("SIDENAV.teams.title"),
          icon: "fal fa-sitemap",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.teams.showAll"),
              route: "/teams/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.teams.add"),
              route: "/teams/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "questions",
          title: this.$t("SIDENAV.questions.title"),
          icon: "fal fa-question",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.questions.showAll"),
              route: "/questions/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.questions.add"),
              route: "/questions/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "users",
          title: this.$t("SIDENAV.users.title"),
          icon: "fal fa-user",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: this.$t("SIDENAV.users.showAll"),
              route: "/users/all",
              hasPermission: true,
            },
            {
              key: "create",
              title: this.$t("SIDENAV.users.add"),
              route: "/users/create",
              hasPermission: true,
            },
          ],
        },
        {
          key: "event",
          title: 'Event Attendees',
          icon: "fal fa-user-tie",
          hasPermission: true,
          children: [
            {
              key: "index",
              title: 'Show All',
              route: "/events/all",
              hasPermission: true,
            },
          ],
        },
        
      ];

      return sideNavRoutes.filter(item => item.hasPermission);
    },
    // End:: Side Navbar Items

    // Start:: Single Navbar Item Children
    sideNavbarItemChildren() {
      return children  => {
        return children.filter(item => item.hasPermission);
      };
    },
    // End:: Single Navbar Item Children

    // Start:: Extract Side Nav Keys
    extractSideNavItemsKeys() {
      let extractedKeys = [];
      this.sideNavbarList.forEach((element) => {
        extractedKeys.push(element["key"]);
      });
      return extractedKeys;
    },
    // End:: Extract Side Nav Keys
  },

  data() {
    return {
      // Start:: Controle Open Tabs Data
      openKeys: [],
      // End:: Controle Open Tabs Data

      // Start:: Modal Controlling Data
      logoutConfirmationModalIsOpen: false,
      // End:: Modal Controlling Data
    };
  },

  methods: {
    // Start:: Vuex Auth Actions
    ...mapActions({
      logout: "AuthenticationModule/logout",
      getAuthedUserPermissions: "PermissionsModule/getAuthedUserPermissions",
    }),
    // End:: Vuex Auth Actions

    // Start:: Render Side Menu
    async renderSideMenuBasedOnUserPermissions() {
      await this.getAuthedUserPermissions();

      this.sideNavbarList.forEach((sideMenuItem) => {
        // Start:: Adding Parent Tap Permission
        this.authedUserPermissions.forEach((permissionItem) => {
          if (sideMenuItem.key === permissionItem.parent_key) {
            sideMenuItem.hasPermission = permissionItem.has_permission;
          }
        });
        // End:: Adding Parent Tap Permission

        // Start:: Adding Children Taps Permissions If Founded
        if (!!sideMenuItem.children) {
          sideMenuItem.children.forEach((menuItemChildren) => {
            this.authedUserPermissions.forEach((permissionItem) => {
              if (sideMenuItem.key === permissionItem.parent_key) {
                permissionItem.permissions.forEach((childPermissionItem) => {
                  if (menuItemChildren.key === childPermissionItem.key) {
                    menuItemChildren.hasPermission =
                      childPermissionItem.has_permission;
                  }
                });
              }
            });
          });
        }
        // End:: Adding Children Taps Permissions If Founded
      });
    },
    // End:: Render Side Menu

    // Start:: Controle Open Tabs
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.extractSideNavItemsKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // End:: Controle Open Tabs
  },

  created() {
    // Start:: Fire Methods
    // this.renderSideMenuBasedOnUserPermissions();
    // End:: Fire Methods
  },
};
</script>
