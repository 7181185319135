<template>
  <div :class="col ? `col-lg-${col}` : ''">
    <div class="input_wrapper top_label text_editor">
      <label class="form-label" v-if="placeholder">
        {{ placeholder }}
        <span class="text-danger" v-if="required">*</span>
      </label>
      <div class="wrapper w-100">
        <TextEditorLoader v-if="textEditorIsUploadingImage" />

        <ckeditor
          :editor="editor"
          :config="editorConfig"
          @input="updateValue($event)"
          :value="value"
          :disabled="disabled"
        />

        <!-- <vue-editor
          :editorToolbar="customToolbar"
          useCustomImageHandler
          @image-added="handleImageAdded"
          @input="updateValue($event)" 
          :value="value"
          :disabled="disabled"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import TextEditorLoader from "@/components/ui/loaders/TextEditorLoader.vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { Bold, Italic } from "@ckeditor/ckeditor5-basic-styles";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { CloudServices } from "@ckeditor/ckeditor5-cloud-services";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Superscript, Subscript, Strikethrough } from '@ckeditor/ckeditor5-basic-styles'
import {
  AutoImage,
  ImageInsert,
  Image,
  ImageStyle,
  ImageUpload,
  ImageResize,
} from "@ckeditor/ckeditor5-image";
import { Indent, IndentBlock } from "@ckeditor/ckeditor5-indent";
import { Link } from "@ckeditor/ckeditor5-link";
import { List } from "@ckeditor/ckeditor5-list";
import { ListProperties } from "@ckeditor/ckeditor5-list";
import AdjacentListsSupport from "@ckeditor/ckeditor5-list/src/documentlist/adjacentlistssupport.js";
import { MediaEmbed } from "@ckeditor/ckeditor5-media-embed";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
import {
  Table,
  TableToolbar,
  TableCellProperties,
  TableProperties,
} from "@ckeditor/ckeditor5-table";
import { TextTransformation } from "@ckeditor/ckeditor5-typing";
import { Clipboard, DragDropExperimental } from "@ckeditor/ckeditor5-clipboard";
import { SimpleUploadAdapter } from "@ckeditor/ckeditor5-upload";
import { FontColor } from "@ckeditor/ckeditor5-font";
import { HorizontalLine } from "@ckeditor/ckeditor5-horizontal-line";
import { ShowBlocks } from "@ckeditor/ckeditor5-show-blocks";

export default {
  name: "BaseTextEditor",

  inheritAttrs: false,

  components: {
    TextEditorLoader,
  },

  props: {
    // ====== Start:: General Inputs Props ====== //
    col: {
      required: false,
      type: String,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    // ====== End:: General Inputs Props ====== //

    // ====== Start:: Textarea Input Props ====== //
    rows: {
      required: false,
      type: String,
      default: "4",
    },
    // ====== End:: Textarea Input Props ====== //
  },

  data() {
    return {
      // Start:: Loaders Handling Data
      textEditorIsUploadingImage: false,
      // End:: Loaders Handling Data

      // Start:: CK Editor Configs
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        plugins: [
          Alignment,
          Autoformat,
          BlockQuote,
          Bold,
          CloudServices,
          Essentials,
          Heading,
          Image,
          AutoImage,
          ImageInsert,
          ImageStyle, 
          ImageResize, 
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Subscript,
          Superscript,
          Strikethrough,
          Link,
          List,
          ListProperties,
          AdjacentListsSupport,
          FontColor,
          Clipboard,
          DragDropExperimental,
          HorizontalLine,
          ShowBlocks,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          Table,
          TableToolbar,
          TextTransformation,
          SimpleUploadAdapter,
          TableProperties,
          TableCellProperties,
        ],

        toolbar: {
          items: [
            "alignment",
            "heading",
            "|",
            "fontColor",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "showBlocks",
            'strikethrough', 
            'subscript', 
            'superscript',
            "|",
            "outdent",
            "indent",
            "|",
            "imageUpload",
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'imageResize',
            'imageTextAlternative',
            '|',
            "blockQuote",
            "insertTable",
            "horizontalLine",
            "undo",
            "redo",
          ],
        },
        indentBlock: {
          offset: 1,
          unit: "em",
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        language: "en",
        image: {
          toolbar: [],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: "https://backend.daleelfm.com/api/upload_photos",

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,DELETE,PATCH,POST,PUT",
            "Access-Control-Allow-Headers":
              "Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date",
            Authorization: `Bearer ${this.$store.getters["AuthenticationModule/getAuthenticatedUserData"].token}`,
          },
        },
      },
      // End:: CK Editor Configs
    };
  },

  methods: {
    updateValue(e) {
      this.$emit("input", e);
    },

    async handleImageAdded(img, Editor, cursorLocation) {
      this.textEditorIsUploadingImage = true;
      const REQUEST_DATA = new FormData();
      REQUEST_DATA.append("file", img);
      try {
        let res = await this.$axios({
          url: "upload_general_photos",
          method: "POST",
          data: REQUEST_DATA,
        });
        // console.log("UPLOADED SRC =>", res.data.data.file);
        this.textEditorIsUploadingImage = false;
        let url = res.data.url;
        Editor.insertEmbed(cursorLocation, "image", url);
      } catch (error) {
        this.textEditorIsUploadingImage = false;
        this.$message.error(error.response.data.message);
      }
    },
  },
};
</script>
