// START:: IMPORTING ROUTER
import router from "../../../router/index.js";
// END:: IMPORTING ROUTER

export default {
    // START:: SET AUTHENTICATED USER DATA
    setAuthenticatedUserData(context, payload) {
        context.commit("setAuthenticatedUserData", payload);
    },
    // END:: SET AUTHENTICATED USER DATA

    // START:: LOGOUT  METHOD
    logout() {
        localStorage.removeItem("urc_user_id");
        localStorage.removeItem("urc_user_token");
        localStorage.removeItem("urc_userName");
        localStorage.removeItem("urc_user_type");
        localStorage.removeItem("urc_user_avatar");
        router.replace("/");
        location.reload();
    },
    // END:: LOGOUT  METHOD
};
