<template>
  <footer class="container-fluid">
      <div class="row">
          <div class="logo col-md-3 col-md-12">
             <img src="@/assets/images/footer/logo.png" />
          </div>
      <div class="links col-md-4 col-sm-6">
          <div class="row">
              <div class="site-map col-6">
              <h6>SiteMap</h6>
              <ul>
                  <li>Home</li>
                  <li>Agenda</li>
                  <li>Speakers</li>
                  <li>Sponsers</li>
                  <li>About Conference</li>
                  <li>Contact</li>
              </ul>
           </div>
          <div class="additional col-6">
              <h6>Additional Pages</h6>
              <ul>
                  <li>Scientific committee</li>
                  <li>Organizing Committee</li>
              </ul>
          </div>
          </div>
      </div>
      <div class="contact-text col-md-3 col-sm-6">
          <p><Icon icon="material-symbols:phone-in-talk" style="color:#F3601F"/> 966123412313</p>
          <p><Icon icon="radix-icons:envelope-open" style="color:#F3601F"/> info@urgentcare.com</p>
          <p>Follow us on social media platforms</p>
          <div class="social-icons">
              <Icon class="icon" icon="ri:facebook-fill"/>
              <Icon class="icon" icon="mdi:instagram" />
              <Icon class="icon" icon="mdi:twitter" />
              <Icon class="icon" icon="mingcute:snapchat-fill"/>
              <Icon class="icon" icon="jam:linkedin" />
          </div>
      </div>
      </div>

  </footer>
</template>

<style scoped>
footer{
      background-image: url('@/assets/images/footer/1.png');
      background-position: center -10px;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 20px;
      min-height: 500px;
      display: flex;
      align-items: center;
  }
  .logo{
      flex: 1;
      text-align: center;
  }
  .links{
      text-align: center;
  }
  .contact{
      flex: 2;
      display: flex;
  }
  .contacts{
  display: flex;
  justify-content: space-around;
  }

  .social-icons{
      display: flex;
      font-size: larger;
      color: #1552E9;
  }
  .icon{
      margin: 0 10px;
  }
  .contact-text{
      flex: 1;
  }
  ul{
      list-style: none;
      padding: 0;
  }
  ul li{
      color: #1552E9;
      margin-bottom: 5px;
  }
  h6{
      color: #F3601F;
      margin-bottom:20px;
  }
  
  @media (max-width:920px) {
      footer{
          background-image: none;
      }
      
      .contact-text{
          text-align: center;
      }
      .social-icons{
          justify-content: center;
      }
  }

</style>


<script>
import { Icon } from '@iconify/vue2';
export default {
  name: "TheFooter",
  components:{
      Icon
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
