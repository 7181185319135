<template>
  <div>
  <footer>
      <div class="logo">
          <img src="@/assets/images/footer/sm_logo.png"/>
      </div>
      <div class="contact-text">
          <p class="contact-p"><Icon icon="material-symbols:phone-in-talk" style="color:#F3601F"/> 966123412313</p>
          <p class="contact-p"><Icon icon="radix-icons:envelope-open" style="color:#F3601F"/> info@urgentcare.com</p>
          <div class="social-icons">
              <Icon class="icon" icon="ri:facebook-fill"/>
              <Icon class="icon" icon="mdi:instagram" />
              <Icon class="icon" icon="mdi:twitter" />
              <Icon class="icon" icon="mingcute:snapchat-fill"/>
              <Icon class="icon" icon="jam:linkedin" />
          </div>
      </div>

  </footer>
  <div class="copy">
          @All Copyrights received to urgent care conference 2024
      </div>
  </div>
</template>
<style scoped>
  footer{
      background-position: center -100px;
      background-size: cover;
      display: flex;
      justify-content: space-around;
      padding: 20px;
  }
  .logo{
      flex: 1;
      display: flex;
      justify-content: center;
      align-content: center;
      width: 170px;
      height:153px ;
  }
  .links{
      flex: 2;
      display: flex;
      justify-content: space-around;
  }
  .contact{
      flex: 2;
      display: flex;
  }
  .contacts{
  display: flex;
  justify-content: space-around;
  }

  .social-icons{
      display: flex;
      font-size: larger;
      color: #1552E9;
      justify-content: center;
  }
  .icon{
      margin: 0 10px;
  }
  .contact-text{
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: flex-end;
  }
  ul{
      list-style: none;
      padding: 0;
  }
  ul li{
      color: #1552E9;
      margin-bottom: 5px;
  }
  h6{
      color: #F3601F;
      margin-bottom:20px;
  }

  .contact-p{
      margin-bottom: 0;
  }
  .copy{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #1552E9;
      margin-bottom: 10px;
      color: #fff;
      height: 58px;
  }
</style>
<script>
import { Icon } from '@iconify/vue2';

export default {
  name: "TheSubFooter",
  components:{
   Icon
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
