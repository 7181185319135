<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
     <div class="container">
    <router-link to="/" class="navbar-brand">
      <img src="@/assets/images/logo.png" />
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link" aria-current="page">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/agenda" class="nav-link" >Agenda</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/speakers" class="nav-link">Speakers</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/sponsers" class="nav-link" >Sponsers</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/committees" class="nav-link" >Scientific committee</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/register" class="nav-link" v-if="!user.token">Register</router-link>
        </li>

        <li class="nav-item">
          <router-link to="/login" class="nav-link" v-if="!user.token">Login</router-link>
        </li>

        <li class="nav-item dropdown" v-if="user.token">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           <Icon icon="material-symbols:account-circle-full" />  My account 
          </a>
          <ul class="dropdown-menu" style="padding: 5px;">
            <li><a class="dropdown-item" href="#">Profile</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#" @click="logout">Logout</a></li>
          </ul>
        </li>
      </ul>
      
    </div>
  </div>
</nav>
</template>

<style scoped>

  nav{
    height: 150px;
    background-color:white !important;
    justify-content: center !important;
    
  }
  .navbar-brand img{
    margin-top: -32px;
    margin-right: 30px;
    padding: 10px 0;
  }
  .navbar-nav{
    width: 100%;
    justify-content: space-between;
  }

  .navbar-nav li{
    /* width: 48px; */
  }
  .navbar-nav li a{
    color:  #1552E9;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .navbar-collapse{
    background-color: #fff;
    padding:20px 20px;
    margin-left: 10px;
  }
</style>
<script>
import { mapGetters, mapActions} from "vuex";
import { Icon } from '@iconify/vue2';

export default {
  name: "TheHeader",
  components:{
    Icon
  },
  computed: {
    // Start:: Vuex Getters
    ...mapGetters({
      getAuthenticatedUserData: "AuthenticationModule/getAuthenticatedUserData",
    }),
    // End:: Vuex Getters
  },

  data() {
    return {
      user: null
    };
  },

  methods: {
    ...mapActions({
      logout: "AuthenticationModule/logout",
    }),
  },

  created() {
    this.user = this.getAuthenticatedUserData;
  },
};
</script>
