import Vue from "vue";
import VueRouter from "vue-router";

// Start:: Importing Middleware
import auth from "../middleware/auth.js";
// End:: Importing Middleware

// Start:: Importing Router Components
import Authentication from "../pages/Authentication.vue";
import AppContentWrapper from "../pages/AppContentWrapper.vue";
import WebsiteWrapper from "../pages/WebsiteWrapper.vue";
import HomePage from "../pages/HomePage.vue";
// End:: Importing Router Components

Vue.use(VueRouter);
const routes = [
  // ***************** Start:: Auth Routes Config

  {
    path:'/',
    component:WebsiteWrapper,
    children:[
      {
        path: "/",
        name: "HomePage",
        component: () => import("@/views/HomePage.vue"),
      },
      {
        path: "/agenda",
        name: "Agenda",
        component: () => import("@/views/Agenda.vue"),
      },
      {
        path: "/committees",
        name: "Committee",
        component: () => import("@/views/Committee.vue"),
      },
      {
        path: "/speakers",
        name: "Speakers",
        component: () => import("@/views/Speaker.vue"),
      },
      {
        path:"/sponsers",
        name:"Sponsers",
        component:()=>import("@/views/Sponsers.vue")
      },
      {
        path:'/payment/check_clickpay',
        component:()=>import("@/views/PaymentStatus.vue")
      },
    ]
  },
  {
    path: "/login",
    component: Authentication,
    children: [
      {
        path: "/login",
        component: () => import("@/views/Auth/LoginForm.vue"),
        name: "LoginForm",
      },
      {
        path: "/register",
        component: () => import("@/views/Auth/RegisterForm.vue"),
        name: "RegisterForm",
      },
      {
        path: '/attendee',
        component: ()=> import("@/views/Auth/Attendee.vue")
      },
      {
        path:'/speaker-register',
        component: ()=>import("@/views/Auth/SpeakerRegister.vue"),
        name:"speakerRegister"
      },
      {
        path: "/reset-password-email",
        name: "ResetPasswordEmailForm",
        component: () => import("@/views/Auth/ResetPasswordEmailForm.vue"),
      },
      {
        path: "/email-verification/:verification_type",
        name: "EmailVerificationForm",
        component: () => import("@/views/Auth/EmailVerificationForm.vue"),
      },
      {
        path: "/chang-phone-number",
        name: "ChangePhoneNumberForm",
        component: () => import("@/views/Auth/ChangePhoneNumberForm.vue"),
      },
      {
        path: "/reset-password",
        name: "ResetPasswordForm",
        component: () => import("@/views/Auth/ResetPasswordForm.vue"),
      },
      {
        path:"/complete-data/:uuid",
        name: "CompleteDataForm",
        component:()=>import("@/views/Auth/CompleteDataForm.vue"),
      }
    ],
  },
  // ***************** End:: Auth Routes Config

  // ***************** Start:: Content Routes Config
  {
    path: "/home",
    component: AppContentWrapper,
    children: [
      // Start:: Home Route Config
      {
        path: "/home",
        component: HomePage,
        meta: {
          middleware: [auth],
        },
      },
      // End:: Home Route Config

    ],
  },
  // ***************** End:: Content Routes Config

  // ***************** Start:: Forbidden Route Config
  {
    path: "/forbidden",
    name: "ForbiddenPage",
    component: () => import("@/pages/ForbiddenPage.vue"),
  },
  // ***************** End:: Forbidden Route Config

  // ***************** Start:: Not Found Route Config
  {
    path: "/:notFound(.*)",
    name: "NotFoundPage",
    component: () => import("@/pages/NotFound.vue"),
  },
  // ***************** End:: Not Found Route Config
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
  routes,
});

// Start:: Middleware And Router Guards
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (localStorage.getItem("urc_user_token")) {
    if (
      to.name == "LoginForm" ||
      to.name == "ResetPasswordPhoneNumberForm" ||
      to.name == "PhoneVerification" ||
      to.name == "ChangePhoneNumberForm" ||
      to.name == "ResetPasswordForm"||
      to.name == 'HomePage'||
      to.name == 'Speaker'||
      to.name == 'Committee'||
      to.name == 'Tickets'||
      to.name == 'CompleteDataForm'
    ) {
      return next("/home");
    } else {
      return next();
    }
  }
  return next();
});
// End:: Middleware And Router Guards

export default router;